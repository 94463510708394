import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { SupplierRoleResource } from '@/resource/model';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { supplierRoleService } from '@/api';
import { Message, MessageBox } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { getResourceStatusOptions, getStatusClass, getStatusI18Key, messageError, translation } from '@/utils';
import AddSupplierRole from './add-supplier-role/add-supplier-role.vue';
import PermissionConfig from './permission-config/permission-config.vue';
import { MessageBoxData } from 'element-ui/types/message-box';
import { ResourceStatusEnum } from '@/resource/enum';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
@Component({
  name: 'SupplierRole',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddSupplierRole, PermissionConfig }
})
export default class SupplierRole extends Vue {
  public tableOption: OsTableOption<SupplierRoleResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'supplier:role:save',
      handleClick: (): void => {
        this.addRole();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'supplier:role:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteRole();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'supplier:role:editStatus',
      handleClick: (): void => {
        this.batchUpdateRoleStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'supplier:role:editStatus',
      handleClick: (): void => {
        this.batchUpdateRoleStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];
  public editRow: SupplierRoleResource | null = null;

  public permissionConfigParams = { roleName: '', roleId: 0 };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<SupplierRoleResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'roleCode',
      label: 'role.code',
      minWidth: '80px'
    },
    { prop: 'roleName', label: 'role.name', minWidth: '100px', showOverflowTooltip: true },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '80px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '130px'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<SupplierRoleResource> = {
    fixed: 'right',
    width: '270px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:role:edit',
        handleClick: (item: SupplierRoleResource): void => {
          this.editRole(item);
        }
      },
      {
        operationType: 'permission',
        type: 'text',
        label: 'platformPermission.permissionConfig',
        icon: 'el-icon-set-up',
        permissionCode: 'supplier:role:saveResourcesRole',
        handleClick: (item: SupplierRoleResource): void => {
          this.openPermissionConfigDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:role:delete',
        handleClick: (item: SupplierRoleResource): void => {
          this.deleteRole(item);
        }
      }
    ]
  };

  public roleDialogVisible = false;
  public permissionConfigDialogVisible = false;
  public totalData = 0;

  private selectedRows: Array<SupplierRoleResource> = [];

  private queryForm: Partial<{
    keywords: string;
    status: ResourceStatusEnum | null;
    companyId: number;
  }> = {
    keywords: '',
    status: null,
    companyId: Number(this.$route.query.companyId)
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };
  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.roleTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public handleSelectionChange(selectedData: Array<SupplierRoleResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: SupplierRoleResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<SupplierRoleResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') {
        x.disabled = value.length === 0;
      }
    });
  }

  private addRole(): void {
    this.openRoleDialog();
  }

  private editRole(item: SupplierRoleResource): void {
    this.openRoleDialog(item);
  }

  private deleteConfirm(): Promise<MessageBoxData> {
    return MessageBox.confirm(translation('tip.confirmDelete'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      type: 'warning'
    });
  }

  private deleteRole(data: SupplierRoleResource): void {
    this.deleteConfirm()
      .then(async () => {
        try {
          await supplierRoleService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteRole(): Promise<void> {
    this.deleteConfirm()
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await supplierRoleService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openRoleDialog(data: SupplierRoleResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.roleDialogVisible = true;
  }

  private openPermissionConfigDialog(data: SupplierRoleResource): void {
    this.permissionConfigParams.roleName = data.roleName;
    this.permissionConfigParams.roleId = data.id;
    this.permissionConfigDialogVisible = true;
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    supplierRoleService
      .getList(this.queryForm as SupplierRoleResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 批量修改角色状态
   * @param status 状态 启用还是禁用
   */
  private batchUpdateRoleStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    supplierRoleService
      .batchUpdateRoleStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
}
