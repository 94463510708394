import { supplierRoleService, supplierPermissionService } from '@/api';
import { PermissionResource, SupplierPermissionResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message, Tree } from 'element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'permission-config'
})
export default class PermissionConfig extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false, required: true }) public syncedVisible!: boolean;
  @Prop({ type: Object, required: true })
  public configParams!: { roleName: string; roleId: number };

  public title = 'platformPermission.permissionConfig';
  public resourceIdList: Array<number> = [];
  public treeOptions: Array<SupplierPermissionResource> = [];
  public submitLoading = false;

  public defaultTreeProps = {
    children: 'children',
    label: 'name'
  };

  public treeControl = {
    /**
     * 全部展开
     */
    fold: true,
    /**
     * 选中全部
     */
    selectAll: false
  };

  public dialogOpen(): void {
    Promise.all([this.getPermissionResource(), this.getPermissionByRoleId()])
      .then(resArr => {
        this.treeOptions = resArr[0] || [];
        this.resourceIdList = resArr[1].map(x => x.id);
        (this.$refs.permissionTree as Tree).setCheckedKeys(this.resourceIdList);
      })
      .catch(error => {
        messageError(error);
      });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    this.resetForm();
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    try {
      await supplierRoleService.configurationPermission({
        roleId: this.configParams.roleId,
        resourcesIdList: (this.$refs.permissionTree as Tree).getCheckedKeys()
      });
      Message.success(translation('operationRes.editSuccess'));
      this.closeDialog();
    } catch (error) {
      messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public foldChange(value: boolean): void {
    (this.$refs.permissionTree as Tree).store._getAllNodes().forEach(x => {
      x.expanded = value;
    });
  }

  public checkAllChange(value: boolean): void {
    (this.$refs.permissionTree as Tree).setCheckedNodes(value ? this.treeOptions : []);
  }

  public resetForm(): void {
    this.treeControl = {
      fold: true,
      selectAll: false
    };
    this.resourceIdList = [];
  }

  private setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  private closeDialog(): void {
    this.syncedVisible = false;
  }

  private async getPermissionResource(): Promise<Array<PermissionResource>> {
    return supplierPermissionService.getSupplierPermissionsBySupplier(Number(this.$route.query.companyId));
  }

  private async getPermissionByRoleId(): Promise<Array<PermissionResource>> {
    return supplierRoleService.getPermissionByRoleId(this.configParams.roleId);
  }
}
